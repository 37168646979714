<template>
  <div class="container">
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model="search.kname" @change="Search" placeholder="类目名"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="search.pcode" @change="Search" placeholder="上级类目"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="AddCategoryType">增加主类</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <el-table-column label="名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template v-slot="scope">
          <el-button type="primary" size="small" @click="EditCategorymain(scope.row)">修改</el-button>
          <el-button type="danger" size="small" @click="DeleteCatogryMain(scope.row)">删除</el-button>
          <i style="font-weight: 700" class="el-icon-top sort" @click="CategoryTypeSort(scope.row,2)"></i>
          <i style="font-weight: 700" class="el-icon-bottom sort" @click="CategoryTypeSort(scope.row,1)"></i>
          <el-button type="primary" size="small" @click="Addson(scope.row)">子类目新增</el-button>
          <el-button type="info" size="small" @click="SonList(scope.row.code)">子类目列表</el-button>
          <!-- <el-button type="info" size="small" @click="categorymanage(scope.row)">品类管理</el-button> -->
          <!-- <el-button type="info" size="small" @click="hideson(scope.row)">子类目隐藏</el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title + '类目'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="类目名称" prop="name" label-width="100px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="Validate(Addconfirm)">{{edit?'修改':'新增'}}</el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog title="子类目列表" v-model="sonFormVisible" width="600px">
      <el-table border v-bind:data="sonList">
        <el-table-column label="名称">
          <template v-slot="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="primary" size="small" @click="EditCategorymain(scope.row,'son')">修改</el-button>
            <el-button type="danger" size="small" @click="DeleteCatogryMain(scope.row,'son')">删除</el-button>
            <i style="font-weight: 700" class="el-icon-top sort" @click="CategoryTypeSort(scope.row,2,'son')"></i>
            <i style="font-weight: 700" class="el-icon-bottom sort" @click="CategoryTypeSort(scope.row,1,'son')"></i>
            <el-button type="info" size="small" @click="categorymanage(scope.row)">品类管理</el-button>
            <!-- <el-button type="info" size="small" @click="hideson(scope.row)">子类目隐藏</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="SonCurrentChange"
        v-model:currentPage="sonform.page"
        :page-size="sonform.limit"
        layout="total, prev, pager, next"
        :total="sontotal">
      </el-pagination>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="sonFormVisible = false">取 消</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 品类管理 -->
    <el-dialog title="品类管理" v-model="catogeryFormVisible" width="1000px">
       <div class="search" style="display:flex">
        <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="catogerysearch.kname" @change="catogerySearch" placeholder="名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="catogerysearch.code" @change="catogerySearch" placeholder="品类编号"></el-input>
          </el-form-item>
        </el-form>
        <div>
          <el-button type="primary" size="small" @click="AddCategory">增加</el-button>
        </div>
      </div>
      <!-- 列表 -->
      <el-table border v-bind:data="catogerylist">
        <el-table-column label="名称">
          <template v-slot="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column label="图标">
          <template v-slot="scope">
            <span>{{ scope.row.icon }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button type="primary" size="small" @click="EditCategory(scope.row)">修改</el-button>
            <el-button type="danger" size="small" @click="DeleteCatogry(scope.row)">删除</el-button>
            <i style="font-weight: 700" class="el-icon-top sort" @click="CategorySort(scope.row,2)"></i>
            <i style="font-weight: 700" class="el-icon-bottom sort" @click="CategorySort(scope.row,1)"></i>
            <!-- <el-button type="info" size="small" @click="panelManage(scope.row.id)">面板管理</el-button>
            <el-button type="info" size="small" @click="planManage(scope.row.id)">方案管理</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="catogeryCurrentChange"
        v-model:currentPage="catogerysearch.page"
        :page-size="catogerysearch.limit"
        layout="total, prev, pager, next"
        :total="catogerytotal">
      </el-pagination>
      
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="catogeryFormVisible = false">取 消</el-button>
          <!-- <el-button type="primary" @click="Validate(Addconfirm)">{{catogeryedit?'修改':'新增'}}</el-button> -->
        </span>
      </template>
    </el-dialog>

    <!-- 品类编辑 -->
    <el-dialog :title="catogeryedittitle+'品类'" v-model="catogeryeditdialogFormVisible" width="600px">
      <el-form :model="catogeryeditform" :rules="catogeryeditrules" ref="editform">
        <el-form-item label="品类名称" prop="name" label-width="100px">
          <el-input v-model="catogeryeditform.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图标" prop="icon" label-width="100px">
          <el-input v-model="catogeryeditform.icon" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="catogeryeditdialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="EditValidate(EditAddconfirm)">{{catogeryeditedit?'修改':'新增'}}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
import util from '../../../util/util'
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      search: {
        page: 1,
        limit: 10,
        kname: '',
        pcode: '',
      },
      list: [],
      total: 0,
      title: '',
      dialogFormVisible: false,
      form: {
        code: '',
        name: '',
        pcode: ''
      },
      rules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
      },
      edit: false,
      checkList: ['选中且禁用','复选框 A'],
      sonFormVisible: false,
      sonform: {
        page: 1,
        limit: 10,
        kname: '',
        pcode: '',
      },
      sonList: [],
      sontotal: 0,
      // 品类
      catogeryFormVisible: false,
      catogeryedit: false,
      catogerysearch: {
        page: 1,
        limit: 10,
        type_code: '',
        kname: '',
        code: '',
      },
      catogerylist: [],
      catogerytotal: 0,
      // 品类编辑
      catogeryeditform: {
        code: '',
        type_code: '',
        icon: '',
        name: '',
      },
      catogeryeditrules: {
        name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        icon: [{ required: true, message: '请输入图标', trigger: 'blur' }],
      },
      catogeryedittitle: '',
      catogeryeditedit: false,
      catogeryeditdialogFormVisible: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    EditAddconfirm () {
      this.api.CategoryUpdate(this.catogeryeditform).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.catogeryedittitle+'成功')
          this.catogerySearch()
          this.catogeryeditdialogFormVisible = false
          return
        }
        this.$message.error(this.catogeryedittitle+'失败' + res.data.msg)
      })
    },
    DeleteCatogry(item) {
      this.$confirm('是否删除该品类?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.CategoryDelete({code:item.code}).then(res => {
          if (res.data.code == 200) {
            this.$message({type: 'success',message: '删除成功!'});
            this.catogerySearch()
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    CategorySort (item, action) {
      this.api.CategoryOrder({
        code: item.code,
        action: action
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('更改排序成功')
          this.catogerySearch()
          return
        }
        this.$message.error('更改排序失败' + res.data.msg)
      })
    },
    AddCategory () {
      this.catogeryeditform.icon = ''
      this.catogeryeditform.name = ''
      this.catogeryedittitle = '新增'
      this.catogeryeditedit = false
      this.catogeryeditdialogFormVisible = true
    },
    catogerySearch () {
      this.api.CategoryList(this.catogerysearch).then(res => {
        if (res.data.code == 200) {
          this.catogeryFormVisible = true
          this.catogerylist = res.data.data.data
          this.catogerytotal = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    catogeryCurrentChange(val) {
      this.catogerysearch.page = val
      this.catogerySearch()
    },
    EditCategory(item) {
      this.catogeryeditform = {}
      this.catogeryedittitle = '编辑'
      this.catogeryeditedit = true
      this.api.CategoryDetail({
        code: item.code
      }).then(res => {
        if (res.data.code == 200) {
          this.catogeryeditform = res.data.data
          this.catogeryeditdialogFormVisible = true
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    SonList(code) {
      this.sonform.pcode = code
      this.api.CategoryTypeList({
        page: this.sonform.page,
        limit: this.sonform.limit,
        kname: '',
        pcode: code,
      }).then(res => {
        if (res.data.code == 200) {
          this.sonList = res.data.data.data
          this.sontotal = res.data.data.page_info.total
          this.sonFormVisible = true
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    SonCurrentChange(val) {
      this.sonform.page = val
      this.SonList()
    },
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    Search () {
      this.api.CategoryTypeList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    AddCategoryType () {
      this.form = {}
      this.edit = false
      this.dialogFormVisible = true
      this.title = '新增主'
    },
    EditCategorymain(item,type) {
      this.form = {}
      this.edit = true
      this.title = '编辑主'
      this.api.CategoryTypeDetail({
        code: item.code
      }).then(res => {
        if (res.data.code == 200) {
          this.form = res.data.data
          this.dialogFormVisible = true
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    DeleteCatogryMain(item,type) {
      this.$confirm('是否删除该类目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.CategoryTypeDelete({
          code: item.code
        }).then(res => {
          if (res.data.code == 200) {
            this.$message({type: 'success',message: '删除成功!'});
            if (type == 'son') {
              this.SonList(this.sonform.pcode)
            } else {
              this.Search()
            }
            return
          }
          this.$message.error('删除失败' + res.data.msg)
        })
        
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    categorymanage (item) {
      // this.$router.push('/iotmanage/category/categorymanage/'+ item.code)
      this.catogerysearch.type_code = item.code
      this.catogeryeditform.type_code = item.code
      this.catogerySearch(item)
    },
    hideson() {

    },
    Addson(item) {
      this.form = {}
      this.edit = false
      this.dialogFormVisible = true
      this.title = '新增子'
      this.form.pcode = item.code
    },
    EditValidate: function (call) {
      this.$refs['editform'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    Addconfirm () {
      this.api.CategoryTypeUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          if (this.sonFormVisible) {
            this.SonList(this.sonform.pcode)
            this.$message.success('编辑类目成功')
            this.dialogFormVisible = false
            this.sonFormVisible = false
          } else {
            this.$message.success(this.title + '类目成功')
            this.Search()
            this.dialogFormVisible = false
          }
          return
        }
        this.$message.error(this.title + '类目失败' + res.data.msg)
      })
    },
    CategoryTypeSort (item,action) {
      this.api.CategoryTypeOrder({
        code: item.code,
        action: action
      }).then(res => {
        if (res.data.code == 200) {
          this.$message.success('更改排序成功')
          this.Search()
          return
        }
        this.$message.error('更改排序失败' + res.data.msg)
      })
    },
    ChangeTime (item) {
      return util.dateFormat(item)
    }
  },
  filters: {},
  mounted () {
    this.Search()
  },
  created () { }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.sort {
  font-size: 16px;
  margin: 0 5px;
  cursor: pointer;
  font-weight: 700px!important;
}
</style>
